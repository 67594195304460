import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Header } from "./components/header";
import MonthlyInvoiceReport from "./app/reports/monthlyInvoiceReport";
import InitiativesDashboard from "./app/initiatives/initiativesDashboard";
import InitiativesDetail from "./app/initiatives/initiativesDetail";
import InitiativesDetailStep from "./app/initiatives/initiativesDetailStep";
import { Footer } from "./components/footer";
import Registration from "./app/registration/registration";
import { createContext, useEffect, useState } from "react";
import Login from "./app/login/login";
import Settings from "./app/settings/settings";
import Verify from "./app/verify/verify";
import AuthenticationService from "./app/service/authService";
import commonService from "./app/service/commonService";
import { Breadcrumb } from "./components/breadcrumb";
import PreSaleDashboard from "./components/preSale/preSaleDashboard";
import CallToActionPreSale from "./components/preSale/callToActionPreSale";
import PreSaleLoading from "./components/preSale/preSaleLoading";
import PreSalePlanSelection from "./components/preSale/preSalePlanSelection";
import { RedirectComponent } from "./components/redirect";
import AccessReview from "./components/preSale/accessReview";
import ResellerProposal from "./components/reseller/resellerProposal";
import CloudResources from "./components/cloudResource/cloudResources";
import AppRedirect from "./app/redirect/redirect";
import PageNotFound from "./app/pageNotFound/pageNotFound";
import ResellerAccounts from "./components/reseller/resellerAccounts";
import ResellerUnmappedSubscripitons from "./components/reseller/resellerUnmappedSubscriptions";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

export const MyContext: any = createContext(null);

const pages = [
  { name: "Step", href: "initiativesDetail", current: false },
  { name: "Detail", href: "initiativesDetailStep", current: true },
];
const App = () => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const { instance, accounts, inProgress } = useMsal();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const isMsalAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const [init, setInit] = useState<boolean>(false);

  const [pageList, setPageList] = useState<any[]>([]);

  const headerRouteList = [
    "/register",
    "/login",
    "/verifyToken",
    "/newAccount",
    "/newAccountProsessing",
    "/newAccountPotential",
    "/accessReview",
    "/redirect"
  ];

  const [isCustomer, setIsCustomer] = useState(null);

  const [searchParams] = useSearchParams();
  const customerId = searchParams.get('customerId');

  useEffect(() => {
    if (!init) {
      getVersions();
      setInit(true);
    }

    if(inProgress === InteractionStatus.Login){
      return;
    }
    
    authGuard();
    checkLogin();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Clear localStorage and sessionStorage
      localStorage.clear();
      sessionStorage.clear();
    };

    // Attach the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const checkLogin = async () => {
    const userData = commonService.userDetail();
    const loggedIn = headerRouteList.indexOf(location.pathname) === -1;
    setIsLoggedIn(loggedIn);
    if (loggedIn) {
      if(commonService.isValidToken(navigate)) {
        commonService.selectedAccount.id = customerId;
        if (!userData?.isVerified) {
          navigate("/verifyToken");
        } else if (accounts.length > 0) {
          await commonService.setMsalAccounts(accounts, navigate).then(() => {
            commonService.parseNavigate(`/redirect?path=/`, navigate);
            setIsLoggedIn(commonService.isValidToken(navigate) as boolean);
          })
        } else {
          setTimeout(() => {
            navigate(`/redirect?path=${location.pathname}`);
          }, 1);
        }
      } else {
        navigate("/login");
      }
    }
    setBreadCrump(location?.pathname);
  }

  const authGuard = () => {
    if(['/register', '/login'].indexOf(location.pathname) === -1 && !commonService.isValidToken(navigate)) {
      navigate("/login");
    }
    setTimeout(() => {
      let isCustomAuthenticated = false;
      if(accounts.length > 0){
        isCustomAuthenticated = isMsalAuthenticated;
      }else{
        isCustomAuthenticated = commonService.isValidToken(navigate) as boolean;
      }

      const isLoggedIn = commonService.isValidToken(navigate) as boolean;
      setIsCustomAuthenticated(isCustomAuthenticated);
      setIsLoggedIn(isLoggedIn);
      const isVerified = commonService.userDetail()?.isVerified;
      setIsVerified(isCustomAuthenticated && isLoggedIn && isVerified);
    }, 1);
    
  }

  useEffect(() => {
    if (accounts.length > 0) {      
      setIsLoggedIn(true);
      setMsalAccount().then(() => {
        if (commonService.isValidToken(navigate)) {
          commonService.parseNavigate(`/redirect?path=/`, navigate);
        }
      });
     
    }
  }, [instance, accounts, inProgress]);

  const setMsalAccount = async (e?: any) => {
    await commonService.setMsalAccounts(accounts, navigate);
  }

  useEffect(() => {
    setBreadCrump(location?.pathname);
    if(inProgress === InteractionStatus.Login){
      return;
    }
    authGuard();
  }, [location]);

  const setBreadCrump = (pathname?: string) => {
    const pathName = pathname?.toString()?.split("/")[1] ?? "";
    if (pathName === "initiativesDetail") {
      setFocus(pathName);
      setPageList([pages[0]]);
    } else if (pathName === "initiativesDetailStep") {
      setFocus(pathName);
      setPageList(pages);
    } else {
      setPageList([]);
    }
  };

  const setFocus = (pathName: string) => {
    pages.forEach((x) => {
      x.current = false;
      if (x.href === pathName) {
        x.current = true;
      }
    });
  };

  const getVersions = () => {
    AuthenticationService.authHealthcheck().then((response: any) => {
      commonService.authVersion = response?.data || {};
    });
    AuthenticationService.portalHealthcheck().then((response: any) => {
      commonService.portalVersion = response?.data || {};
    });
  };

  const [isCustomAuthenticated, setIsCustomAuthenticated] = useState<boolean>(false);

  return (
    <MyContext.Provider value={{ isCustomer, setIsCustomer }}>
        <div className="min-h-screen">
          {isLoggedIn && isCustomAuthenticated && isVerified && <Header />}
          <main>
            <Breadcrumb
              pageList={pageList || []}
              pathName={location.pathname || ""}
            />

            <Routes>
              {isCustomer && isCustomAuthenticated && (
                <>
                  <Route path="/" element={<MonthlyInvoiceReport />} />
                  <Route
                    path="/Initiatives"
                    element={<InitiativesDashboard />}
                  />
                  <Route
                    path="/initiativesDetail/:id/"
                    element={<InitiativesDetail />}
                  />
                  <Route
                    path="/InitiativesDetailStep/:id/"
                    element={<InitiativesDetailStep />}
                  />
                  <Route
                    path="/newAccount/"
                    element={<CallToActionPreSale />}
                  />
                  <Route
                    path="/newAccountProsessing/"
                    element={<PreSaleLoading />}
                  />
                  <Route
                    path="/newAccountPotential/"
                    element={<PreSaleDashboard />}
                  />
                  <Route
                    path="/newAccountPlanSelection/"
                    element={<PreSalePlanSelection />}
                  />

                  <Route path="/accessReview/" element={<AccessReview />} />

                  <Route path="/CloudResources/" element={<CloudResources />} />
                </>
              )}

              <Route path="/login" element={<Login />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/verifyToken" element={<Verify />} />
              <Route path="/redirectAccount" element={<RedirectComponent />} />
              <Route path="/redirect" element={<AppRedirect />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="/register" element={<Registration />} />

              {!isCustomer && isCustomAuthenticated && (
                <>
                <Route
                  path="/resellerProposal/"
                  element={<ResellerProposal />}
                />
                <Route
                  path="/resellerAccounts/"
                  element={<ResellerAccounts />}
                />
                <Route
                  path="/resellerUnmappedSubscripitons/"
                  element={<ResellerUnmappedSubscripitons />}
                />
                </>
              )}
            </Routes>
          </main>
          <div className="sticky top-[100vh]">{isLoggedIn && isCustomAuthenticated && <Footer />}</div>
        </div>
      </MyContext.Provider>
  );
};

export default App;
