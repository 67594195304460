import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { msalConfig } from "./authConfig";
import { AccountInfo, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from "@azure/msal-react";
import React, { useEffect } from 'react';

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const MsalWrapper = () => {
  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        // Safely cast payload to the correct type
        const payload = event.payload as { account: AccountInfo };
        const account = payload?.account;
        if (account) {
          msalInstance.setActiveAccount(account);
        }
      }
    });

    return () => {
      // Cleanup callback to avoid memory leaks in StrictMode
      if(callbackId) msalInstance.removeEventCallback(callbackId);
    };
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <App />
        <ToastContainer role="alert" autoClose={10000} position="top-left" />
      </BrowserRouter>
    </MsalProvider>
  );
};

root.render(
  <React.StrictMode>
    <MsalWrapper />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
