import { LogLevel } from "@azure/msal-browser";

// src/authConfig.js
export const msalConfig = {
    auth: {
        clientId: "a1b91e4a-0f73-4a61-a7a6-33784f6ef16f",
        authority: "https://login.microsoftonline.com/345cf4c7-2a50-4981-b96d-24df1488295f/v2.0",
         redirectUri: "https://glass.floatfinops.io/waitonlogin.html"
        // redirectUri: "http://localhost:3000/waitonlogin.html" // Your app's redirect URI
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where the token is stored
        storeAuthStateInCookie: true, // Set to true if you have issues with cookies
    }
};

export const loginRequest = {
    scopes: ["User.Read"], // Define scopes you want to request, e.g., user profile
};
