import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../spinner/spinner";
import AuthenticationService from "../service/authService";
import commonService from "../service/commonService";
import { toast } from "react-toastify";
import backgroundImg from '../../assets/logo/AdobeStock_616564080.jpeg';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const errorState: any = {
  firstName: { hasError: false, required: true },
  lastName: { hasError: false, required: true },
  userName: { hasError: false, required: true },
  email: { hasError: false, required: true },
  password: { hasError: false, required: true },
  companyName: { hasError: false, required: true },
  companySize: { hasError: false, required: true },
  companyWebsite: { hasError: false, required: true },
  phone: { hasError: false, required: true }
};

const providerOptions = [
  "Microsoft",
  "Float FinOps"
];

const registrationModel: Register = {
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  password: "",
  companyName: "",
  companySize: "",
  companyWebsite: "",
  phone: "",
  timeZone: "Pacific Standard Time",
  customerId: "",
  invitationCode: "",
  provider: providerOptions[0]
};

type InputState = {
  isRequired: boolean;
  display: boolean;
  disable: boolean;
}

type RegistrationInputState = {
  firstName: InputState;
  lastName: InputState;
  userName: InputState;
  password: InputState;
  email: InputState;
  companyName: InputState;
  companySize: InputState;
  companyWebsite: InputState;
  phone: InputState;
  timeZone: InputState;
};

const registrationOptions = [
  "none-invited-microsoft",
  "invited-microsoft",
  "none-invited-username-password", 
  "invited-username-password"
];

const baseState: RegistrationInputState = {
  firstName: { isRequired: true, display: true, disable: false },
  lastName: { isRequired: true, display: true, disable: false },
  userName: { isRequired: true, display: true, disable: false },
  password: { isRequired: true, display: true, disable: false },
  email: { isRequired: true, display: true, disable: false },
  companyName: { isRequired: true, display: true, disable: false },
  companySize: { isRequired: true, display: true, disable: false },
  companyWebsite: { isRequired: false, display: true, disable: false },
  phone: { isRequired: false, display: true, disable: false },
  timeZone: { isRequired: true, display: true, disable: false },
};

const createRegistrationState = (overrides: Partial<RegistrationInputState>) => {
  return {
    ...baseState,
    ...overrides,
  };
};

const registrationStateOptions: { [key: string]: RegistrationInputState } = {
  "none-invited-microsoft": createRegistrationState({
    userName: { isRequired: false, display: false, disable: false },
    password: { isRequired: false, display: false, disable: false },
    email: { isRequired: false, display: false, disable: false },
  }),
  "invited-microsoft": createRegistrationState({
    userName: { isRequired: false, display: true, disable: true },
    email: { isRequired: false, display: true, disable: true },
    companyName: { isRequired: false, display: false, disable: false },
    companySize: { isRequired: false, display: false, disable: false },
    companyWebsite: { isRequired: false, display: false, disable: false },
  }),
  "none-invited-username-password": baseState,
  "invited-username-password": createRegistrationState({
    email: { isRequired: true, display: true, disable: true },
    companyName: { isRequired: false, display: false, disable: false },
    companySize: { isRequired: false, display: false, disable: false },
  }),
};

const registerButtonTextOptions = [
  "Register with Microsoft",
  "Register with Username & Password"
];

export default function Registration() {
  const [registerModel, setRegisterModel] = useState<Register | any>(
    registrationModel
  );
  const [error, setErrorState] = useState<any>(errorState);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const dbkProfile = commonService.dbkProfile;
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('');
  const [provider, setProvider] = useState<string>('');
  const { instance, accounts } = useMsal();
  const [registrationOption, setRegistrationOption] = useState<string>(registrationOptions[0]);
  const [registerButtonText, setRegisterButtonText] = useState<string>(registerButtonTextOptions[0]);
  const [registrationState, setRegisterState] = useState<RegistrationInputState>(registrationStateOptions[registrationOptions[0]]);

  const handleRegistrationOption = (option: string) => {
    setRegistrationOption(option);
    initRegisterModel();
  };

  const isSelected = (option: string) => registrationOption === option;

  const initRegisterModel = () => {
    const initModel: Register = JSON.parse(JSON.stringify(registrationModel));
    initModel.companySize = commonService.companySizeList[0].value;
    initModel.timeZone = commonService.currentTimeZone();
    setRegisterModel(initModel);
  }

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    const initModel: Register = JSON.parse(JSON.stringify(registrationModel));
    initModel.companySize = commonService.companySizeList[0].value;
    initModel.timeZone = commonService.currentTimeZone();
    const params = new URLSearchParams(window.location.search);
    let customerId = params.get("customerId");
    let code = params.get("code");
    let email = params.get("email");
    let paramProvider = params.get("provider") ?? "";
    setProvider(paramProvider);
    setSelectedCustomerId(customerId ?? '');

    if(code){
      if(paramProvider === "Microsoft"){
        setRegistrationOption(registrationOptions[1]);
      }else{
        setRegistrationOption(registrationOptions[3]);
      }
    }else{
      setRegistrationOption(registrationOptions[0]);
    }
    if(customerId){
      initModel.customerId = customerId;
      initModel.invitationCode = code ?? '';
      initModel.email = email ?? '';
      if(registrationOption === registrationOptions[1]){
        initModel.userName = email ?? '';
      }
    }
    setRegisterModel(initModel);
  }, [provider]);

  useEffect(() => {
    checkError();
  }, [registerModel, error]);

  useEffect(() => {
    if(registrationOption === registrationOptions[0] || registrationOption === registrationOptions[1]){
      setRegisterButtonText(registerButtonTextOptions[0]);
      registerModel.provider = providerOptions[0];
    }else{
      setRegisterButtonText(registerButtonTextOptions[1]);
      registerModel.provider = providerOptions[1];
    }
    setRegisterState(registrationStateOptions[registrationOption]);
  }, [registrationOption]);

  const updateErrorStateOnRegistrationStateChange = () => {
    const updatedErrorState = Object.keys(registrationState).reduce((acc: any, field: string) => {
      const fieldKey = field as keyof RegistrationInputState;
      if (errorState.hasOwnProperty(fieldKey)){
        acc[field] = {
          ...errorState[field],
          required: registrationState[fieldKey].isRequired, // Update the 'required' field
        };
      }
      return acc;
    }, {});
    setErrorState(updatedErrorState);
  };

  useEffect(() => {
    updateErrorStateOnRegistrationStateChange();
  }, [registrationState]);

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    if(fieldName === 'password') {
      field.hasError = !commonService.passwordPattern?.test(value);
    } else if(fieldName === 'email') {
      field.hasError = !value || !commonService.isValidEmail(value);
    } else {
      field.hasError = !value;
    }
    const model = JSON.parse(JSON.stringify(registerModel));
    model[fieldName] = value;
    setRegisterModel(model);
    error[fieldName] = field;
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(error) || []) {
      if ((error[key]?.required && !registerModel[key]) ||
      (key === 'password' && (registrationOption === registrationOptions[2] || registrationOption === registrationOptions[3]) && error?.password?.hasError) ||
      (key === 'email' && error?.email?.hasError)) {
        isError = true;
      }
    }
    setHasError(isError);
  };

  const onRegister = async (e?: any) => {
    e?.preventDefault();
    let oid;
    if ((registrationOption === registrationOptions[0] || registrationOption === registrationOptions[1]) && accounts.length === 0) {
      // User is not logged in with MSAL, prompt them to log in
      try {
        const loginResponse = await instance.loginPopup(loginRequest); // Wait for the login to complete
        const msalAccount = loginResponse.account; // Get the logged-in account
        oid = msalAccount?.idTokenClaims?.oid; // Extract the OID
        registerModel.userName = msalAccount.username;
        registerModel.email = msalAccount.username;
        registerModel.provider = providerOptions[0];
      } catch (error) {
          toast.error("Login required to proceed with registration.");
          setLoading(false);
          return;
      }
    }else{
      registerModel.provider = providerOptions[1];
    }
    if((registrationOption === registrationOptions[0] || registrationOption === registrationOptions[1]) && accounts.length > 0) {
      // If already logged in, fetch the OID from the existing account
      const msalAccount = accounts[0];
      oid = msalAccount.idTokenClaims?.oid;
      registerModel.userName = msalAccount.username;
      registerModel.email = msalAccount.username;
      registerModel.provider = providerOptions[0];
    }else{
      registerModel.provider = providerOptions[1];
    }

    if ((registrationOption === registrationOptions[0] || registrationOption === registrationOptions[1]) && !oid) {
      toast.error("Unable to retrieve OID for registration.");
      setLoading(false);
      return;
    }

    const entry: Register = JSON.parse(JSON.stringify(registerModel));
    if(!entry.customerId) {
      delete entry.customerId;
    } else {
      entry.companySize = '';
    }
    if((registrationOption === registrationOptions[0] || registrationOption === registrationOptions[1]) && oid){
      entry.userId = oid;
    }
    setLoading(true);
    AuthenticationService.register(entry)
      .then((response: any) => {
        commonService.showMessage(response?.data || {});
        if (response?.data?.isSuccess) {
          navigateURL("/login");
        }
        setLoading(false);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const navigate = useNavigate();
  const navigateURL = (link: string) => {
    navigate(link);
  };

  return (
    <div className="flex min-h-full flex-1 h-screen">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-md lg:w-96">
            <div className="flex justify-center">
              <img
                className="h-16 w-auto"
                src={dbkProfile.imgUrl}
                alt={dbkProfile.name}
              />
            </div>
            <h2 className="mt-3 text-2xl text-center font-bold leading-9 tracking-tight text-gray-900">
              Register
            </h2>
            <p className="mt-2 text-sm text-center leading-6 text-gray-500">
              Already a member?{" "}
              <a
                href="#"
                onClick={() => navigateURL("/login")}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Click here to login
              </a>
            </p>

            <div className="mt-4">
              <div>

                {
                  (registrationOption === registrationOptions[0] || registrationOption === registrationOptions[2]) &&
                  <div className="flex flex-col md:flex-row gap-6 justify-center">
                    <div
                      onClick={() => handleRegistrationOption(registrationOptions[0])}
                      className={`w-full md:w-1/2 bg-white shadow-md rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 cursor-pointer ${
                        isSelected(registrationOptions[0])
                          ? "border-2 border-indigo-600"
                          : ""
                      }`}
                    >
                      <div className="text-center">
                        <div className="flex justify-center mb-4">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="50" height="50">
                            <rect x="0" y="0" width="60" height="60" fill="#F1511B" />
                            <rect x="68" y="0" width="60" height="60" fill="#80CC28" />
                            <rect x="0" y="68" width="60" height="60" fill="#00ADEF" />
                            <rect x="68" y="68" width="60" height="60" fill="#FBBC09" />
                          </svg>

                        </div>
                        <h2 className="text-md font-semibold mb-4 text-center">
                          <span className="block">Register with</span>
                          <span className="block">Microsoft</span>
                        </h2>
                        <p className="text-sm text-gray-600">
                          Sign up quickly using your Microsoft account.
                        </p>
                      </div>
                    </div>

                    <div
                      onClick={() => handleRegistrationOption(registrationOptions[2])}
                      className={`w-full md:w-1/2 bg-white shadow-md rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 cursor-pointer ${
                        isSelected(registrationOptions[2])
                          ? "border-2 border-green-600"
                          : ""
                      }`}
                    >
                      <div className="text-center">
                        <div className="flex justify-center mb-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-12 w-12 text-green-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M16 21v-2a4 4 0 0 0-8 0v2" />
                            <circle cx="12" cy="7" r="4" />
                            <line x1="12" y1="3" x2="12" y2="5" />
                          </svg>
                        </div>
                        <h2 className="text-md font-semibold mb-4 text-center">
                          <span className="block">Register with</span>
                          <span className="block">Username & Password</span>
                        </h2>
                        <p className="text-sm text-gray-600">
                          Create an account using a username and password.
                        </p>
                      </div>
                    </div>
                  </div>
                }

                <form className="space-y-0" onSubmit={onRegister}>
                  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    {
                      registrationState.firstName.display &&
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          First name
                        </label>
                        <div className="mt-1 mb-1">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            value={registerModel.firstName}
                            required={registrationState.firstName.isRequired}
                            onChange={(e) =>
                              onInputChange("firstName", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              error?.firstName?.hasError
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {error?.firstName?.hasError && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          First Name is required.
                        </p>
                        )}
                      </div>
                    }

                    {
                      registrationState.lastName.display &&
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Last name
                        </label>
                        <div className="mt-1 mb-1">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            value={registerModel.lastName}
                            onChange={(e) =>
                              onInputChange("lastName", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              error?.lastName?.hasError
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {error?.lastName?.hasError && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Last Name is required.
                        </p>
                        )}
                      </div>
                    }
                  </div>
                  
                  {
                    registrationState.userName.display &&
                    <div>
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Username
                      </label>
                      <div className="mt-1 mb-1">
                        <input
                          id="username"
                          name="username"
                          type="text"
                          autoComplete="username"
                          required={registrationState.userName.isRequired}
                          disabled={registrationState.userName.disable}
                          value={registerModel.userName}
                          onChange={(e) =>
                            onInputChange("userName", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.userName?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : "",
                              (registrationOption === registrationOptions[0] || registrationOption === registrationOptions[1]) ? "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200": ""
                          )}
                        />
                      </div>
                      {registrationState.userName.isRequired && error?.userName?.hasError && (
                        <p
                        className="mt-2 text-sm text-red-600"
                        id="order-error"
                      >
                        Username is required.
                      </p>
                      )}
                    </div>
                  }

                  {
                    registrationState.email.display &&
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email address
                      </label>
                      <div className="mt-1 mb-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          disabled={registrationState.email.disable}
                          required={registrationState.email.isRequired}
                          value={registerModel.email}
                          onChange={(e) =>
                            onInputChange("email", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.email?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : "",
                              !!selectedCustomerId ? "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200": ""
                          )}
                        />
                      </div>
                      {registrationState.email.isRequired && error?.email?.hasError && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="order-error"
                          >
                            {!registerModel.email ? 'Email is required.': 'Invalid Email'}
                          </p>
                      )}
                    </div>
                  }

                  {
                    registrationState.password.display && 
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                      <div className="mt-1 mb-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          required={registrationState.password.isRequired}
                          value={registerModel.password}
                          onChange={(e) =>
                            onInputChange("password", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.password?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {registrationState.password.isRequired && error?.password?.hasError && (
                        <p
                        className="mt-2 text-sm text-red-600"
                        id="order-error"
                      >
                        Has minimum 8 characters, At least one uppercase, one lowercase, one digit, one special character.
                      </p>
                      )}
                    </div>
                  }

                  {
                    !selectedCustomerId &&
                    <>
                    {
                      registrationState.companyName.display &&
                      <div>
                        <label
                          htmlFor="companyname"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Company Name
                        </label>
                        <div className="mt-1 mb-1">
                          <input
                            id="companyname"
                            name="companyname"
                            type="text"
                            required={registrationState.companyName.isRequired}
                            value={registerModel.companyName}
                            onChange={(e) =>
                              onInputChange("companyName", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              error?.companyName?.hasError
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {registrationState.companyName.isRequired && error?.companyName?.hasError && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Company Name is required.
                        </p>
                        )}
                      </div>
                    }

                    {
                      registrationState.companySize.display &&
                      <div>
                        <label
                          htmlFor="companySize"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Company Size
                        </label>
                        <div className="mt-1 mb-1">
                          <select
                            id="companySize"
                            name="companySize"
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              error?.companySize?.hasError
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                            value={registerModel.companySize}
                            required={registrationState.companySize.isRequired}
                            onChange={(e) =>
                              onInputChange("companySize", e?.target?.value)
                            }
                          >
                            {commonService.companySizeList?.map((size: {name: string, value: string}) => (
                                <option
                                  value={size.value}
                                  key={size.value}
                                >
                                  {size.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {registrationState.companySize.isRequired && error?.companySize?.hasError && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Company Size is required.
                        </p>
                        )}
                      </div>
                    }

                    {
                      registrationState.companyWebsite.display &&
                      <div>
                        <label
                          htmlFor="companyWebsite"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Company Website
                        </label>
                        <div className="mt-1 mb-1">
                          <input
                            id="companyWebsite"
                            name="companyWebsite"
                            type="text"
                            required={registrationState.companyWebsite.isRequired}
                            value={registerModel.companyWebsite}
                            onChange={(e) =>
                              onInputChange("companyWebsite", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              error?.companyWebsite?.hasError
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {registrationState.companyWebsite.isRequired && error?.companyWebsite?.hasError && (
                          <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Company Website is required.
                        </p>
                        )}
                      </div>
                    }

                    {
                      registrationState.phone.display &&
                      <div>
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Phone
                        </label>
                        <div className="mt-1 mb-1">
                          <input
                            id="phone"
                            name="phone"
                            type="number"
                            required={registrationState.phone.isRequired}
                            value={registerModel.phone}
                            onChange={(e) =>
                              onInputChange("phone", e?.target?.value)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            )}
                          />
                        </div>
                      </div>
                    }
                    </>
                  }

                  {
                    registrationState.timeZone.display &&
                    <div>
                      <label
                        htmlFor="timeZone"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Timezone
                      </label>
                      <div className="mt-1 mb-1">
                        <select
                          id="timezone"
                          name="timezone"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                          value={registerModel.timeZone}
                          required={registrationState.timeZone.isRequired}
                          onChange={(e) =>
                            onInputChange("timeZone", e?.target?.value)
                          }
                        >
                          {commonService.timeZones()?.map((timeZone: string) => (
                              <option
                                value={timeZone}
                                key={timeZone}
                              >
                                {timeZone}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  }

                  <div>
                    <button
                      type="submit"
                      disabled={hasError || loading}
                      className={commonService.classNames(
                        "flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                        hasError || loading ? "disabled:opacity-75" : "",
                        "mt-4"
                      )}
                    >
                      <Spinner show={loading} />
                      {registerButtonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={backgroundImg}
            alt=""
          />
        </div>
    </div>
  );
}
